.home-container-item-carousel {
  max-width: 100vw;
  height: 40rem;
  background-position: center;
  background-size: cover;
}

.carousel-control-prev {
  width: 10%;
}

.carousel-control-next {
  width: 10%;
}

.linha-conteudo-hero {
  margin-left: 9rem;
}