@import '../variables';
.footer {
  position: relative;
  p {
    font-size: 13px;
    line-height: 1.7em;
  }
}

.social {
  .social-icon {
    border: 2px solid #868e96;
    border-radius: 50%;
    transition: all 0.5s;
    width: 42px;
    height: 42px;
    display: block;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
  }
}

.footer-terms {
  li {
    &:last-child {
      &:after {
        content: '';
      }
    }
    a {
      &:hover {
        color: $white !important;
        transition: all 0.5s;
      }
    }
    &:after {
      content: '|';
      margin: 0 20px;
      color: #757575;
    }
  }
}

.footer .img-lojas {
  max-height: 48px;
  margin-right: 10px;
  margin-left: 10px;
}
