@import '../variables';

.noticias-widget {
  border-width: 0 !important;

  h3 {
    font-size: 1.125em;
    font-weight: bold;
  }

  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      color: $primary;
    }
  }

  .content {
    padding-top: 1em;
    display: flex;
    flex-direction: column;
    gap: 2em;
  }

  .noticia {
    padding-inline: 0;

    .imagem {
      width: 100%;
      height: 100%;
      display: flex;
      flex: 1;
      background-size: cover;
      background-position: top center;
      border-radius: $border-radius;
    }

    .noticia-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      padding-top: 0.125em;

      :first-child {
        font-size: 1em;
        font-weight: bold;
      }

      :last-child {
        font-size: 0.8em;
        color: $gray-500;
      }
    }
  }
}
