/* Paleta */
$primary: #004028;
$secondary: #ffffff;
$danger: #d6171f;
$success: #23a94f;
$warning: #fdc940;
$dark: #1a1a1a;
$light: #f9f9f9;
$info: #37b8df;
$muted: #adb5bd;

$white: #ffffff;
$blue: #224ad8;
$red: #d6171f;
$yellow: #ffff33;
$green: #23a94f;

$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #efefef;
$gray-400: #e6effe;
$gray-500: #adb5bd;
$gray-600: #89a2b5;
$gray-700: #495057;
$gray-800: #2d2d2d;
$gray-900: #1d262d;

// stylelint-disable
$colors: (
  'primary': $primary,
  'secondary': $secondary,
  'danger': $danger,
  'success': $success,
  'warning': $warning,
  'dark': $dark,
  'light': $light,
  'info': $info,
  'white': $white,
  'blue': $blue,
  'red': $red,
  'yellow': $yellow,
  'green': $green,
  'gray': $gray-300,
);

$paleta: (
  'verde-escuro': $primary,
  'amarelo': $secondary,
  'verde': $green,
  'branco': $white,
);

$color-bg: #f7f7f7;
$color-accent: lighten($primary, 0.4);
$color-accent-light: lighten($color-accent, 0.4);
$color-accent-hover: $color-accent-light;
$color-text: #484848;
$color-text-secondary: lighten($dark, 0.5);

$border-radius: 10px;

/* Tipografia */
$font-size-base: 16px;
$font-size-small: 12px;
$font-family-base: Poppins, Arial, sans-serif;
$font-family-secondary: Poppins, sans-serif;

/* Responsividade (max-width)
 * https://getbootstrap.com/docs/5.2/layout/breakpoints/#available-breakpoints */
$screen-xxl: 1400px;
$screen-xl: 1200px;
$screen-lg: 992px;
$screen-md: 768px;
$screen-sm: 576px;
