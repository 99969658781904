@import '../variables';

.page-noticia {
  padding-block: 3em;

  .breadcrumbs {
    display: flex;
    gap: 1em;
    color: $gray-500;
    & * {
      color: $gray-500;
    }
  }

  .header {
    border-radius: $border-radius;
  }

  h1 {
    font-weight: bold;
    color: $color-text;
  }
}
