.loading-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  z-index: 9999999;
  &[datatype='loaded'] {
    @include fadeout-animation;
  }
  & ~ div {
    overflow: hidden;
    height: 100vh;
  }
}

.loading-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  place-items: center;
  padding: 15px;
  background-color: $gray-300;
  border-radius: 50%;
  box-shadow: 1px 1px 4px rgba($dark, 0.5);
  flex: 0;
  width: fit-content;
  z-index: 99999;
}

.loading-backdrop {
  //@include fadeout-animation;
  z-index: 99998;
  width: 100%;
  height: 100%;
  background-color: $gray-300;
}
