/*
 * TYPHOGRAPHY.scss
 *
 * Stylesheet para tipografia
 */

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal;
}

p,
span {
  font-size: 16px;
}

input,
select,
option {
  font-size: 10px;
}

table {
  font-size: 11px;
  td {
    font-weight: 300;
  }
}

p {
  margin: 10px 0 0 0;

  &:first-child {
    margin: 0;
  }
}

h1 {
  font-size: 48px;
  line-height: 63px;

  &.subhead {
    font-size: 30px;
    color: $color-text-secondary;
    line-height: 36px;
  }
}

h2 {
  font-size: 30px;
  line-height: 36px;

  &.subhead {
    font-size: 24px;
    color: $color-text-secondary;
    line-height: 32px;
  }
}

h3 {
  font-size: 24px;
  line-height: 32px;

  &.subhead {
    font-size: 18px;
    color: $color-text-secondary;
    line-height: 24px;
  }
}

h4 {
  font-size: 16px;
  line-height: 24px;

  &.subhead {
    font-size: 12px;
    color: $color-text-secondary;
    line-height: 16px;
  }
}

h5 {
  font-size: 14px;
  line-height: 18px;

  &.subhead {
    font-size: 10px;
    color: $color-text-secondary;
    line-height: 12px;
  }
}

h6 {
  font-size: 12px;
  line-height: 16px;

  &.subhead {
    font-size: 8px;
    color: $color-text-secondary;
    line-height: 10px;
  }
}

.text-bold {
  font-weight: 700;
}

blockquote {
  font-size: 13px;
  font-style: italic;
  border-left: 3px solid $color-accent;
  margin-bottom: 0;
  padding-left: 10px;
}

.highlight {
  background-color: $color-accent;
  color: $white;
}

.page-title {
  font-weight: 500;
  text-transform: capitalize;
  font-size: 20px;

  &:last-child {
    margin-bottom: 30px;
  }

  &.page-title--not-last {
    margin-top: 20px;
  }
}

.page-subhead {
  margin-bottom: 20px;

  &.subhead {
    font-size: 14px;
    opacity: 0.7;
  }
}

::selection {
  color: $white;
  background: rgba($color-accent, 0.5);
}

button {
  font-size: 14px;
}

$sizes: (
  'xsmall': 8px,
  'small': 10px,
  'medium': 14px,
  'big': 16px,
  'large': 32px,
  'xlarge': 64px,
);

@each $name, $value in $sizes {
  .font-size-#{$name} {
    font-size: $value;
  }
}

.text-justify {
  text-align: justify;
}
