@import '../variables';

.notification {
  margin-top: 5vh;
  width: 100%;
  color: $white;
  padding: 10px;
  border-radius: 0.375rem;
  text-align: center;
  transition: all 300ms ease;
  &:empty {
    visibility: hidden;
    opacity: 0;
    padding: 0;
    line-height: 0;
    margin: 0;
    height: 0;
  }
}

.error {
  background-color: transparentize($danger, 0.4);
}

.success {
  background-color: transparentize($success, 0.4);
}
