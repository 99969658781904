@import '../variables';

.convenio-card {
  border-width: 0 !important;

  --cols: 4;
  --gap-col-offset: calc(calc(var(--cols) - 1) / var(--cols) * var(--gap));
  /* Calcula a width ideal para N colunas, considerando o tamanho do gap */
  width: calc(100% / var(--cols) - var(--gap-col-offset));
  @media screen and (max-width: $screen-lg) and (min-width: $screen-sm) {
    --cols: 3;
  }
  @media screen and (max-width: $screen-sm) {
    --cols: 2;
  }
}

.card-img {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.card-body {
  display: flex;
  justify-content: space-between;
  padding-block: 1rem;
  flex-direction: column;

  .title {
    font-size: $font-size-base;
    font-weight: bold;
    line-height: 1.5em;
  }

  .content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-grow: 1;
    & p {
      font-size: $font-size-small;
      line-height: 1.375em;
      margin: 0;
    }
    & h4 {
      color: $primary;
    }
  }

  footer {
    display: flex;
    gap: 1em;
    align-items: center;
    padding-top: 1em;
  }

  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      color: $primary;
    }
  }
}
