@media screen and (max-width: $screen-md) {
  .modal-full-height {
    margin: 0;
  }
  .custom-navbar-collapse {
    display: grid;
    justify-content: center;
  }

  .logout-icon {
    padding-top: 5px !important;
  }

  .navbar-nav {
    text-align: center;
  }

  .offset-3 {
    margin-left: 0% !important;
  }

  .areajustificativa {
    min-height: 45px;
    min-width: 300px;
  }

  .quadro-links { 
    flex-direction: column;
  }
}

@media screen and (min-width: $screen-md) {
  .navbar-nav {
    align-items: center;
  }
}