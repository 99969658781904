@import '../variables';

.login-form__wrapper {
  position: relative;
  width: fit-content;
  padding-block: 20px;
  max-width: 400px; //

  .login-form__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    place-items: center;
    padding: 15px;
    background-color: $gray-300;
    border-radius: 50%;
    box-shadow: 1px 1px 4px rgba($dark, 0.5);
    transition: background-color 600ms ease;

    &.login-form__success {
      background-color: $success;
      & > * {
        color: $white;
        animation: sucesso 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
    }
  }
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  img {
    width: 200px;
  }

  input[type='text'], 
  input[type='password'] {
    width: 75%;
  }

  & *[class*='col'] {
    display: flex;
    justify-content: center;
  }

  .login-form__error {
    width: 70%;
    background-color: transparentize($danger, 0.4);
    color: $white;
    padding: 10px;
    border-radius: 0.375rem;
    text-align: center;
    transition: all 300ms ease;
    &:empty {
      opacity: 0;
      padding: 0;
    }
  }

  .login-form__content {
    gap: 10px;
    margin-bottom: 10px;
  }
}

@keyframes sucesso {
  0% {
    transform: scale(0) rotateZ(-20deg);
  }
  100% {
    transform: scale(1) rotateZ(0);
  }
}
