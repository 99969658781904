html,
body,
#root,
main {
  width: 100%;
  height: 100%;
}

body {
  font-family: $font-family-base;
  color: $color-text;
  overflow-x: hidden !important;
  position: relative;
}

section {
  min-height: fit-content;
  padding-block: 90px;
  box-sizing: content-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-base;
}

.btn,
.btn:focus,
button,
button:focus {
  outline: none !important;
}

.navbar-dark {
  color: white;

  .nav-link {
    color: white;
    font-weight: 300;
  }

  .nav-item {
    padding: 0px 20px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.footer-margin {
  margin-top: 8vh;
  padding: 50px;
}

img.logotipo-ascesa {
  /* 5585 ÷ 1890 */
  aspect-ratio: 2.941176470588235;
  &[logosize='small'] {
    width: 80px;
  }
  &[logosize='medium'] {
    width: 150px;
  }
  &[logosize='big'] {
    width: 200px;
  }
}

img[loading='lazy'] {
  @include fadein-animation;
}

.page-titulo-container {
  //mt-5 mb-3 px-4 px-md-0 text-center text-md-start
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  padding-inline: 1.5rem;
  text-align: center;
  @media screen and (min-width: $screen-md) {
    padding-inline: 0;
    text-align: start;
  }
  & > .row {
    gap: 3rem;
  }
}

.quadro-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
