@import '../variables';

.subtitulo {
  font-size: $font-size-base;
  line-height: 1.5em;
}

.resultados-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.resultados {
  --gap: 1rem;
  display: flex;
  gap: var(--gap, 1rem);
  flex-wrap: wrap;
  position: relative;
  height: fit-content !important;
}

.conteudo-wrapper {
  width: 100%;
  height: 100vh;
  min-height: 300px;
  padding: 0;
  margin: 0;
}

.mapa-wrapper {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
