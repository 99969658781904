$skeleton-color: $gray-300;

@mixin skelly-shine {
  $shine-color: lighten($skeleton-color, 3%);
  background-image: linear-gradient(
    70deg,
    transparent 43%,
    $shine-color 48% 52%,
    transparent 57%
  );
  background-size: 300%;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  animation: skelly-shine-animation 1.5s ease infinite,
    fade-in 0.3s ease forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes skelly-shine-animation {
  30% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 100%;
  }
}

.skeleton-rect {
  border-radius: $border-radius;
  width: 100%;
  aspect-ratio: 2;
  background-color: $skeleton-color;
  @include skelly-shine;
}

.skeleton-text {
  height: 1em;
  background-color: $skeleton-color;
  @include skelly-shine;
}
