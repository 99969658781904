.input-otp {
  width: 20%;
  margin: 0px 25px;
  text-align: center;
}

.btn-assembleia-voto {
  background-color: var(--cor, inherit);
  color: $white;

  &:hover,
  &:active,
  &:focus,
  &:not(:disabled) {
    background: var(--cor, inherit) !important;
  }

  &:disabled {
    background: $gray-500;
  }
}

.span-validate {
  color: $danger;
  font-size: 12px;
  margin-top: 25px;
}

.no-style-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.span-name {
  color: #0d7efd;
  cursor: pointer;
  text-decoration: underline;
}

.areajustificativa {
  min-height: 85px;
  min-width: 600px;
  border: 1px solid rgb(0, 64, 40);
}