
$gaps : (
  '6': 4rem,
  '7': 5rem,
  '8': 6rem,
  '9': 7rem,
  '10': 8rem
  );
  
  @each $name, $value in $gaps {
    .gap-#{$name} {
      gap: $value;
    }
  }
  
  .btn {
    font-size: 16px;
    padding: 5px 25px;
  }

@each $name, $value in $colors {
  .bg-#{$name} {
    background-color: #{$value} !important;
  }

  .bg-#{$name}-light {
    background-color: #{lighten($value, 5)} !important;
  }

  .bg-#{$name}-lighter {
    background-color: #{rgba(lighten($value, 20), 0.5)} !important;
  }

  .bg-#{$name}-lightest {
    background-color: #{rgba(lighten($value, 20), 0.25)} !important;
  }

  .bg-#{$name}-dark {
    background-color: #{darken($value, 5)} !important;
  }

  .bg-soft-#{$name} {
    background-color: rgba(($value), 0.1) !important;
  }

  .text-#{$name} {
    color: #{$value} !important;
  }

  .border-#{$name} {
    border-color: #{$value} !important;
  }

  .btn-soft-#{$name} {
    background-color: rgba(($value), 0.25) !important;
    color: #{$value} !important;
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus,
    .open > .dropdown-toggle.btn-primary {
      background: darken($value, 4%);
    }
  }

  .btn-#{$name} {
    --color: #{$value};
    background: #{$value};
    border-color: #{$value};
    color: contrastColor($value);

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus,
    .open > .dropdown-toggle.btn-primary {
      background: scale-color($value, $lightness: -30%);
      border-color: scale-color($value, $lightness: -30%);
      // color: darken($white, 10%);
    }

    &:disabled,
    &.disabled {
      background-color: $muted;
      border-color: $muted;
    }
  }
}
.btn-outline {
  background-color: transparent;
  background-image: none;
  border-width: 1px !important;
  color: var(--color);
}

.btn-minimal {
  background-color: transparent;
  background-image: none;
  border: none;
  font-size: $font-size-base;
  padding: 0;
  border-radius: 0;
  flex: none !important;
  vertical-align: baseline;
  color: var(--color, inherit);

  &:hover,
  &:focus,
  &:active,
  &.active,
  &.focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):active:focus,
  .open > .dropdown-toggle.btn-primary {
    background-color: transparent;
    font-weight: 900;
    box-shadow: none;
    color: var(--color, inherit);
  }
}

.form-field {
  &::placeholder {
    color: $gray-500;
    font-size: smaller;
  }
  font-size: 20px;
  letter-spacing: 0.05em;
  text-align: center;
}

.tabela-jogos {
  // border-collapse: separate;
  tbody {
    td,
    tr {
      border: none;
    }
    tr:nth-child(2n) {
      border-bottom: 5px solid white;
    }
  }
}

.form-check-input:checked {
  position: relative;
  background-color: $secondary;
  border-color: $secondary;
  &::after {
    content: '✓';
    font-weight: bold;
    position: absolute;
    inset: 0;
  }
}

.form-switch .form-check-input:focus {
  filter: brightness(0.9) sepia(0.6);
}

.condicao-field {
  display: flex;
  padding-inline: 0;
  width: fit-content;
  margin-inline: 0;
}

.btn-main {
  font-size: 0.75rem;
  font-weight: bold;
  border-width: 0;
  border-radius: $border-radius;
  padding-inline: 4.125em;
  padding-block: 0.5em;
  letter-spacing: 0.04em;
  width: fit-content;

  &.btn-big {
    font-size: 1.25rem;
    line-height: 1.35em;
  }

  @media screen and (max-width: $screen-sm) {
    &.btn-big {
      font-size: 1rem;
    }
  }
}

.text-btn {
  text-decoration: none;
  color: inherit;
  font-size: 0.915em;
  font-weight: bold;
  border-width: 0;
  &:hover,
  &:active,
  &:focus {
    color: inherit;
  }
}

.infobox-googlemaps {
  h4 {
    font-weight: bold;
    padding-bottom: 1em;
  }
  p {
    font-size: $font-size-small;
    line-height: 1.375em;
    margin-top: 0;
  }
}

@import 'hamburguer';
@import 'navbar';