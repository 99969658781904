.modal-dialog {
  &.modal-full-height {
    position: relative;
    margin-block: 0;
    height: 100vh;

    & .modal-content {
      border-radius: 3px;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      background-clip: border-box;
      @include scrollbar;
    }

    & .modal-body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 0;
      height: 90%;
    }
  }

  &.no-header .modal-header {
    border: 0;
    position: absolute;
    right: 0;
    z-index: 100;
  }
}
