@import '../variables';

#homepage {
  section:not(.hero) {
    &:nth-child(2n + 1) {
      background-color: $color-bg;
    }
    &:nth-child(2n) {
      background-color: $light;
    }
  }
  h1,
  h2 {
    font-size: 2.75rem;
    line-height: 1.35em;
  }
  h2 {
    font-weight: bold;
  }
  @media screen and (max-width: $screen-sm) {
    h1,
    h2 {
      font-size: 1.5rem;
    }
    section {
      padding-block: 50px;
      & > .container {
        padding-inline: 2.5rem;
      }
    }
  }
}

.hero {
  background: url('../../images/ascesa-hero2.jpg');
  background-color: $primary;
  background-position: right bottom;
  background-size: contain;
  background-repeat: no-repeat;
  padding-block: 100px;
  padding-inline: 0;
  box-sizing: border-box;
  height: 600px;

  h1 {
    color: $secondary;
  }

  button {
    font-size: 1.25em;
    color: $primary;
    font-weight: bold;
    border-width: 0;

    padding-inline: 4.125em;
    padding-block: 0.5em;
    &:hover,
    &:active,
    &:focus {
      color: $primary;
    }
  }

  @media screen and (max-width: $screen-sm) {
    background-position: center bottom;
    background-size: auto 55vh;
    button {
      width: 100%;
    }
  }
}

.beneficio-card {
  display: flex;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 1.5em;
  & .beneficio-card__icone {
    --icon-size: 1em;
    width: var(--icon-size);
    height: var(--icon-size);
    font-size: 3rem;
    padding: 0.3em;
    box-sizing: content-box;
    border-radius: 50%;
    border: 1px solid $primary;
    color: $primary;
    transition: all 0.1s ease;
    background-color: transparent;
  }

  &:hover {
    font-weight: bold;
    color: $primary;
    & .beneficio-card__icone {
      color: $white;
      border-color: $white;
      background-color: $primary;
    }
  }
}

@media screen and (max-width: $screen-sm) {
  #pesquisa-credenciados > .container > .row {
    &:first-child {
      display: flex;
      flex-direction: column-reverse;
      gap: 2rem;
    }
    &:last-child {
      gap: 1rem;
    }
  }
  #seja-conveniado {
    & > .container > .row {
      display: flex;
      flex-direction: column-reverse;
      gap: 2rem;
    }
    .btn-main {
      width: 100%;
    }
  }
}

.img-sejaconveniado {
  height: 600px;
  width: auto;
}
