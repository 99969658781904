@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
    border: 0;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border: 2px solid transparent; //padding
    background-clip: content-box;
    background-color: $gray-500;
    border-radius: 100vw;
    &:hover {
      background-color: $gray-600;
    }
  }
}
