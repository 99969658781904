.multi-input {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: inherit;

  input {
    display: flex;
    text-align: center;
    height: 5rem;
    width: 5rem;
    margin-left: 3px;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    font-size: inherit;
  }

  &.multi-input--error {
    input {
      border: 1px solid #ad4444;
    }
  }
}
