@import './variables';

form {
  .form-select {
    border: none;
    border-bottom: 1px solid rgb(0, 64, 40);
    border-radius: 0px;
    padding: 10px;
  }

  .input-border {
    border: none;
    border-bottom: 1px solid rgb(0, 64, 40);
    padding: 10px;
    &[error='error'] {
      border-bottom-color: $danger;
    }
  }

  .label-mt-top {
    padding-top: 10px;
  }

  label {
    font-weight: bold;
    font-size: 14px;
  }

  .big-margin-area {
    margin-top: 10vh;
  }
  .media-margin-area {
    margin-top: 7vh;
  }
  .small-margin-area {
    margin-top: 3vh;
  }
  .smaller-margin-area {
    margin-top: 1vh;
  }

  .btn-style-anterior {
    background-color: #a8a8a8bd;
    border: none;
    color: #000000;
    padding: 12px 30px;
    margin-right: 10px;
    border-radius: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 20px;
  }
  .btn-style-anterior:hover {
    background-color: #a8a8a88e;
  }

  .btn-style-prox {
    background-color: #004028;
    border: none;
    color: #fff;
    padding: 12px 30px;
    margin-right: 10px;
    border-radius: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 20px;
  }

  .btn-style-prox:hover {
    background-color: #004029e0;
  }

  .footer-margin {
    margin-top: 8vh;
    padding: 50px;
  }
  .margin-input-from-img {
    margin-top: 5vh;
  }

  .btn-style {
    background-color: #004028;
    border: none;
    color: #fff;
    padding: 12px 30px;
    margin-right: 10px;
    border-radius: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 20px;
  }
  .span-margin-from-btn-check {
    margin-left: 10px;
    font-weight: 0.875em;
  }
  button,
  input[type='submit'],
  input[type='reset'] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
  .label-check {
    margin: 6px;
    font-size: small;
    font-weight: 400;
    font-family: Arial, Helvetica, sans-serif;
  }
  .font-small {
    font-size: 10px;
  }
  .align-horizontal {
    grid-row: auto;
    grid-template-columns: 1fr 3fr;
  }
  .font-Input-value {
    font-size: 12px;
  }
  .react-select {
    .react-select__control {
      border: none;
      border-bottom: 1px solid rgb(0, 64, 40);
      border-radius: 0px;
      padding: 10px;
      box-shadow: none;
      padding: 0px;
    }
    .react-select__indicator-separator {
      background-color: #fff;
    }
  }
  .btn-icon-form {
    height: 15px;
  }
  .btn-style-icons {
    background-color: #a8a8a8bd;
    border: none;
    color: #000000;
    padding: 6px 18px;
    margin-left: -15px;
    border-radius: 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }

  .btn-style-icons:hover {
    background-color: #a8a8a88e;
    outline: 2px solid #000000;
  }
}

.form__form-group-input-wrap--error-above {
  margin-bottom: 15px;

  .form__form-group-error {
    top: -28px;
  }
}

.form__form-group-error {
  font-size: 10px;
  line-height: 13px;
  color: #ad4444;
  margin-bottom: -5px;
  display: block;
  margin-top: 5px;
}

.form__form-group-input-wrap {
  width: 100%;
}

.form__form-group-input-wrap {
  width: 100%;
}

.form__form-group-input-wrap--error-above {
  .form__form-group-error {
    position: absolute;
    margin: 0;
    right: 0;
    top: 0;
    padding: 5px 10px;
    background: #ffbcbc;
    border-radius: 3px;

    &:after {
      content: '';
      position: absolute;
      right: 10px;
      bottom: -8px;
      border: 4px solid transparent;
      border-top: 4px solid #ffbcbc;
    }
  }
}
