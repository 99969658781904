/*--- Mixins ---*/
@mixin fadein-animation {
  animation: fade-in 200ms ease;
}

@mixin fadeout-animation {
  animation: fade-out 300ms ease forwards;
}

@mixin surgir-animation {
  animation: surgir 1250ms ease;
}


/*--- Keyframes ---*/
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
  }
}

@keyframes fade-out {
  from {
  }
  to {
    opacity: 0;
  }
}

@keyframes surgir {
  from {
    transform: scale(0) rotateZ(180deg);
  }
  to {
    transform: scale(1) rotateZ(0deg);
  }
}

/*--- Classes ---*/
.fade-in {
  @include fadein-animation;
}

.fade-out {
  @include fadeout-animation;
}

.surgir{
  @include surgir-animation;
}

