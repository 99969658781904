.hamburguer {
  position: relative;
  width: 24px;
  height: 24px;

  div {
    position: absolute;
    width: 100%;
    height: 12.5%;
    background-color: $white;
    border-radius: 2px;
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
  }

  div:nth-child(1) {
    top: 10.5%;
  }

  div:nth-child(2) {
    top: 43.75%;
  }

  div:nth-child(3) {
    top: 77.5%;
  }

  &.hamburguer-open {
    div {
      top: 43.75%;
    }
    div:nth-child(1) {
      transform: rotateZ(45deg);
    }
    div:nth-child(2),
    div:nth-child(3) {
      transform: rotateZ(-45deg);
    }
  }
}
