@import '../variables';

.card-custom {
  &.card {
    height: 300px !important;
  }
  border-width: 0 !important;
  & [class*='card'] {
    border-width: 0 !important;
  }
  &:hover,
  &:active,
  &:focus {
    .card-title {
      color: $primary;
    }
  }
}
.card {
  height: 100% !important;
  border-radius: 25px !important;
  overflow: hidden;
  box-sizing: border-box;

  .card-img-wrapper {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    background-size: cover;
    background-position: top center;
  }
  .card-body {
    height: 50%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    * {
      text-transform: lowercase;
    }
  }
  .card-text {
    max-height: 70%;
  }
  .card-title {
    font-size: 1rem;
    line-height: 1rem;
    margin-bottom: 3px;
    font-weight: bold;
  }
  .card-content {
    font-size: 0.9rem;
    overflow: hidden;
    flex: 1;
  }
  .card-action {
    display: flex;
    // min-height: 40%;
    align-items: flex-end;
    button {
      height: min-content;
      flex-grow: 0;
      width: 100%;
    }
  }
}
